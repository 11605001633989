import Dropzone from "dropzone";
import { Controller } from "@hotwired/stimulus";
import { DirectUpload } from "@rails/activestorage";
import Cropper from "cropperjs";
import { transform } from "lodash";
import pica from "pica";

function blobToFile(theBlob, fileName) {
  //A Blob() is almost a File() - it's just missing the two properties below which we will add
  theBlob.lastModifiedDate = new Date();
  theBlob.name = fileName;
  return theBlob;
}

function getMetaValue(name) {
  const element = findElement(document.head, `meta[name="${name}"]`);
  if (element) {
    return element.getAttribute("content");
  }
}

function findElement(root, selector) {
  if (typeof root == "string") {
    selector = root;
    root = document;
  }
  return root.querySelector(selector);
}

function toArray(value) {
  if (Array.isArray(value)) {
    return value;
  } else if (Array.from) {
    return Array.from(value);
  } else {
    return [].slice.call(value);
  }
}

function removeElement(el) {
  if (el && el.parentNode) {
    el.parentNode.removeChild(el);
  }
}

function insertAfter(el, referenceNode) {
  return referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling);
}

export default class extends Controller {
  static targets = ["input"];

  connect() {
    this.dropZone = createDropZone(this);
    this.hideFileInput();
    this.bindEvents();
    Dropzone.autoDiscover = false; // necessary quirk for Dropzone error in console
  }

  // Private
  hideFileInput() {
    this.inputTarget.disabled = true;
    this.inputTarget.style.display = "none";
  }

  bindEvents() {
    this.dropZone.on("addedfile", (file) => {
      if (this.dropZone.element.classList.contains("license-plate-decoder")) {
        var reader = new FileReader();
        var target = document.getElementById("license-plate");
        var colorField = document.getElementById("colore-rilevato");
        var colorFieldScore = document.getElementById("colore-rilevato-score");
        var modelField = document.getElementById("modello-rilevato");
        var modelFieldScore = document.getElementById("modello-rilevato-score");
        var brandField = document.getElementById("marca-rilevata");
        var brandFieldScore = document.getElementById("marca-rilevata-score");
        var outputField = document.getElementById("auto-detect-output");
        reader.onload = function (event) {
          var base64String = event.target.result;

          var img = new Image();
          img.src = event.target.result;
          img.onload = function () {
            var canvas = document.createElement("canvas");
            var ctx = canvas.getContext("2d");
            var cw = canvas.width;
            var ch = canvas.height;
            var maxW = 640;
            var maxH = 640;

            var iw = img.width;
            var ih = img.height;
            var scale = Math.min(maxW / iw, maxH / ih);
            var iwScaled = iw * scale;
            var ihScaled = ih * scale;
            canvas.width = iwScaled;
            canvas.height = ihScaled;
            ctx.drawImage(img, 0, 0, iwScaled, ihScaled);
            var resizedImage = canvas.toDataURL("image/jpeg", 0.5);
            // console.log(resizedImage);

            const FormData = require("form-data");
            let body = new FormData();
            body.append("upload", resizedImage);
            body.append("regions", "it");
            // body.append("mmc", true);
            fetch("https://api.platerecognizer.com/v1/plate-reader/", {
              method: "POST",
              headers: {
                Authorization: "Token 21d60d751b460dd05fbbace851e454591aa37056",
              },
              body: body,
            })
              .then((res) => res.json())
              .then((json) => {
                console.log(json);
                if (json && json.results && json.results[0]) {
                  if (target) {
                    target.value = json.results[0].plate.toUpperCase();
                    let changeEvent = new Event("input");
                    target.dispatchEvent(changeEvent);
                  } else {
                    console.log("no license plate target");
                  }
                  let currentResult = json.results[0];
                  let outputText = "";
                  if (currentResult.model_make && currentResult.model_make[0]) {
                    if (brandField && modelField && brandFieldScore) {
                      brandField.value = currentResult.model_make[0].make;
                      modelField.value = currentResult.model_make[0].model;
                      brandFieldScore.value = currentResult.model_make[0].score;
                      outputText =
                        outputText +
                        " " +
                        currentResult.model_make[0].make +
                        " " +
                        currentResult.model_make[0].model;
                    }
                  }
                  if (currentResult.color && currentResult.color[0]) {
                    if (colorField && colorFieldScore) {
                      colorField.value = currentResult.color[0].color;
                      colorFieldScore.value = currentResult.color[0].score;
                      outputText =
                        outputText + " " + currentResult.color[0].color;
                    }
                  }
                  if (outputField) {
                    outputField.innerHTML = outputText;
                  }
                }
              })
              .catch((err) => {
                console.log(err);
              });
          };
        };
        reader.readAsDataURL(file);
      }

      if (this.dropZone.element.classList.contains("crop")) {
        if (file.cropped) {
          setTimeout(() => {
            file.accepted && createDirectUploadController(this, file).start();
          }, 500);
          return;
        }
        var ratio = 1;
        if (this.dropZone.element.classList.contains("crop-0-27")) {
          ratio = 1 / 0.27;
        }
        var myDropZone = this.dropZone;
        var controller = this;
        var newFile = file;
        myDropZone.removeFile(file);
        // Create the image editor overlay
        var modalContainer = document.createElement("div");
        modalContainer.classList.add("cropper-modal-container");
        document.body.appendChild(modalContainer);
        var editor = document.createElement("div");
        editor.classList.add("cropper-editor");
        modalContainer.appendChild(editor);
        // Create confirm button at the top left of the viewport
        var buttonConfirm = document.createElement("button");
        buttonConfirm.classList.add(
          "cropper-confirm",
          "btn",
          "btn-primary",
          "btn-lg"
        );
        buttonConfirm.textContent = "Confirm image resize";
        modalContainer.appendChild(buttonConfirm);
        buttonConfirm.addEventListener("click", function () {
          // Get the canvas with image data from Cropper.js
          var canvas = cropper.getCroppedCanvas({
            width: 1024,
            height: 1024,
          });
          // Turn the canvas into a Blob (file object without a name)
          canvas.toBlob(function (blob) {
            // Create a new Dropzone file thumbnail
            myDropZone.createThumbnail(
              blob,
              myDropZone.options.thumbnailWidth,
              myDropZone.options.thumbnailHeight,
              myDropZone.options.thumbnailMethod,
              false,
              function (dataURL) {
                // Update the Dropzone file thumbnail
                myDropZone.emit("thumbnail", newFile, dataURL);
                // Return the file to Dropzone
                // done(blob);
              }
            );
            document.body.removeChild(modalContainer);
            var file = blobToFile(blob, newFile.name);
            file.cropped = true;
            myDropZone.addFile(file);
          });
          // Remove the editor from the view
        });
        // Create an image node for Cropper.js
        var image = new Image();
        image.classList.add("cropper-image-element");
        image.src = URL.createObjectURL(newFile);
        editor.appendChild(image);

        // Create Cropper.js
        var cropper = new Cropper(image, {
          aspectRatio: ratio,
          responsive: true,
          modal: false,
        });
      } else {
        if (file.cropped || file["type"].split("/")[0] != "image") {
          setTimeout(() => {
            file.accepted && createDirectUploadController(this, file).start();
          }, 500);
          return;
        }
        var ratio = 1;
        if (this.dropZone.element.classList.contains("crop-0-27")) {
          ratio = 1 / 0.27;
        }
        var myDropZone = this.dropZone;
        var newFile = file;
        myDropZone.removeFile(file);
        var reader = new FileReader();
        reader.onload = function (event) {
          var base64String = event.target.result;

          var img = new Image();
          img.src = event.target.result;
          img.onload = function () {
            var canvas = document.createElement("canvas");
            var ctx = canvas.getContext("2d");
            var cw = canvas.width;
            var ch = canvas.height;
            var maxW = 1280;
            var maxH = 1280;

            var iw = img.width;
            var ih = img.height;
            var scale = Math.min(maxW / iw, maxH / ih);
            var iwScaled = iw * scale;
            var ihScaled = ih * scale;
            canvas.width = iwScaled;
            canvas.height = ihScaled;
            // ctx.drawImage(img, 0, 0, iwScaled, ihScaled);
            pica({
              features: ["js"],
            })
              .resize(img, canvas)
              .then((result) => pica().toBlob(result, "image/jpeg", 0.9))
              .then((blob) => {
                myDropZone.createThumbnail(
                  blob,
                  myDropZone.options.thumbnailWidth,
                  myDropZone.options.thumbnailHeight,
                  myDropZone.options.thumbnailMethod,
                  false,
                  function (dataURL) {
                    myDropZone.emit("thumbnail", newFile, dataURL);
                  }
                );
                var file = blobToFile(blob, newFile.name);
                file.cropped = true;
                myDropZone.addFile(file);
              });
            // canvas.toBlob(
            //   function (blob) {
            //     // Create a new Dropzone file thumbnail
            //     myDropZone.createThumbnail(
            //       blob,
            //       myDropZone.options.thumbnailWidth,
            //       myDropZone.options.thumbnailHeight,
            //       myDropZone.options.thumbnailMethod,
            //       false,
            //       function (dataURL) {
            //         myDropZone.emit("thumbnail", newFile, dataURL);
            //       }
            //     );
            //     var file = blobToFile(blob, newFile.name);
            //     file.cropped = true;
            //     myDropZone.addFile(file);
            //   },
            //   "image/jpeg",
            //   0.9
            // );
            // console.log(resizedImage);
          };
        };
        reader.readAsDataURL(file);
      }
    });

    this.dropZone.on("removedfile", (file) => {
      file.controller && removeElement(file.controller.hiddenInput);
    });

    this.dropZone.on("canceled", (file) => {
      file.controller && file.controller.xhr.abort();
    });
  }

  get headers() {
    return { "X-CSRF-Token": getMetaValue("csrf-token") };
  }

  get url() {
    return this.inputTarget.getAttribute("data-direct-upload-url");
  }

  get maxFiles() {
    return this.data.get("maxFiles") || 1;
  }

  get maxFileSize() {
    return this.data.get("maxFileSize") || 256;
  }

  get acceptedFiles() {
    return this.data.get("acceptedFiles");
  }

  get addRemoveLinks() {
    return this.data.get("addRemoveLinks") || true;
  }
}

class DirectUploadController {
  constructor(source, file) {
    this.directUpload = createDirectUpload(file, source.url, this);
    this.source = source;
    this.file = file;
  }

  start() {
    this.file.controller = this;
    this.hiddenInput = this.createHiddenInput();
    document.querySelectorAll(`input[type="submit"]`).forEach((element) => {
      element.setAttribute("activeText", element.value);
      element.value = "Uploading";
      element.disabled = true;
    });
    this.directUpload.create((error, attributes) => {
      if (error) {
        removeElement(this.hiddenInput);
        this.emitDropzoneError(error);
      } else {
        this.hiddenInput.value = attributes.signed_id;
        this.emitDropzoneSuccess();
      }
    });
  }

  createHiddenInput() {
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = this.source.inputTarget.name;
    insertAfter(input, this.source.inputTarget);
    return input;
  }

  directUploadWillStoreFileWithXHR(xhr) {
    this.bindProgressEvent(xhr);
    this.emitDropzoneUploading();
  }

  bindProgressEvent(xhr) {
    this.xhr = xhr;
    this.xhr.upload.addEventListener("progress", (event) =>
      this.uploadRequestDidProgress(event)
    );
  }

  uploadRequestDidProgress(event) {
    const element = this.source.element;
    const progress = (event.loaded / event.total) * 100;
    findElement(
      this.file.previewTemplate,
      ".dz-upload"
    ).style.width = `${progress}%`;
  }

  emitDropzoneUploading() {
    this.file.status = Dropzone.UPLOADING;
    this.source.dropZone.emit("processing", this.file);
  }

  emitDropzoneError(error) {
    this.file.status = Dropzone.ERROR;
    this.source.dropZone.emit("error", this.file, error);
    this.source.dropZone.emit("complete", this.file);
    document.querySelectorAll(`input[type="submit"]`).forEach((element) => {
      element.value = element.getAttribute("activeText");
      element.disabled = false;
    });
  }

  emitDropzoneSuccess() {
    this.file.status = Dropzone.SUCCESS;
    this.source.dropZone.emit("success", this.file);
    this.source.dropZone.emit("complete", this.file);
    document.querySelectorAll(`input[type="submit"]`).forEach((element) => {
      element.value = element.getAttribute("activeText");
      element.disabled = false;
    });
  }
}

function createDirectUploadController(source, file) {
  return new DirectUploadController(source, file);
}

function createDirectUpload(file, url, controller) {
  return new DirectUpload(file, url, controller);
}

function createDropZone(controller) {
  return new Dropzone(controller.element, {
    url: controller.url,
    headers: controller.headers,
    maxFiles: controller.maxFiles,
    maxFilesize: controller.maxFileSize,
    acceptedFiles: controller.acceptedFiles,
    addRemoveLinks: controller.addRemoveLinks,
    autoQueue: false,
    // resizeWidth: 100,
    // resizeHeight: 100,
    // resizeQuality: 0.8,
    // resizeMethod: "contain",
  });
}
